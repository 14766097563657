import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "@/queries/client";
import { components } from "@/lib/openapi";

export type Gate = components["schemas"]["GateSchema"];

export function getGateByTerminal(terminal: number) {
  return useQuery({
    queryKey: ["gates", terminal],
    queryFn: async ({ signal }) => {
      const { data, error } = await client.get().GET("/api/gates", {
        signal,
        params: {
          query: {
            terminal: terminal,
          },
        },
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function createGate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      createData: components["schemas"]["GateCreateSchema"],
    ) => {
      const { data, error } = await client.get().POST("/api/gates", {
        body: createData,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["gates"] });
    },
  });
}

export function updateGate(gate_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      updateData: components["schemas"]["GateUpdateSchema"],
    ) => {
      const { data, error } = await client.get().PUT("/api/gates/{gate_id}", {
        params: {
          path: {
            gate_id,
          },
        },
        body: updateData,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["gates"] });
    },
  });
}

export function deleteGate(gate_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data, error } = await client
        .get()
        .DELETE("/api/gates/{gate_id}", {
          params: {
            path: {
              gate_id,
            },
          },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["gates"] });
    },
  });
}
