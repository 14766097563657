import React, { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Component,
  createComponent,
  deleteComponent,
  getComponents,
  updateComponent,
} from "@/queries/component";
import { z } from "zod";
import {
  CrudForm,
  CrudPage,
  FormMode,
  useCrudContext,
} from "@/components/page/CrudPage";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormField } from "@/components/ui/form";
import { SelectInput, TextInput } from "@/components/form/FormControls";
import _ from "lodash";
import { CHART_TYPES } from "@/viz/Charts";
import { useSessionData } from "@/stores/SessionDataContext";
import {
  zoneDirectionOptions,
  zoneFlightTypeOptions,
  zoneMeasureOptions,
  zonePaxTypeOptions,
  zoneTypeOptions,
} from "@/pages/setup/constants";

type DashboardPageParams = {
  dashboardId: string;
};

const formSchema = z.object({
  title: z.string().min(1).max(255),
  chart_type: z.string().min(1).max(255),
  x: z.string().min(1).max(400),
  y: z.string().min(1).max(400),
  width: z.string().min(1).max(400),
  height: z.string().min(1).max(400),

  terminal_id: z.string().optional(),
  zone_type: z.string().optional(),
  measure: z.string().optional(),
  direction: z.string().optional(),
  flight_type: z.string().optional(),
  pax_type: z.string().optional(),

  config: z.object({}),
});

const ComponentForm: FunctionComponent<{
  dashboard: number;
}> = ({ dashboard }) => {
  const { record, setRecord, mode, setMode } = useCrudContext<Component>();

  const createMode = mode === FormMode.Add;
  const makeDefaultValues = (component?: Component) => ({
    title: component?.title || "",
    x: (component?.x || 0).toString(),
    y: (component?.y || 0).toString(),
    width: (component?.width || 4).toString(),
    height: (component?.height || 4).toString(),
    chart_type: component?.chart_type || _.keys(CHART_TYPES)[0],

    terminal_id: component?.terminal_id?.toString() || "",
    zone_type: component?.type || "",
    measure: component?.measure || "",
    direction: component?.direction || "",
    flight_type: component?.flight_type || "",
    pax_type: component?.pax_type || "",

    config: {},
  });

  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: makeDefaultValues(record),
    resolver: zodResolver(formSchema),
  });

  const createMutation = createComponent();
  const updateMutation = updateComponent(record?.id || 0);
  const destroyMutation = deleteComponent(record?.id || 0);

  const onDelete = () => {
    destroyMutation.mutate();
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    const newValues = values;
    [
      "terminal_id",
      "zone_type",
      "measure",
      "direction",
      "flight_type",
      "pax_type",
    ].map((k) => {
      if (values[k] == "") {
        newValues[k] = null;
      }
    });
    if (createMode) {
      // @ts-ignore
      createMutation.mutate({
        ...values,
        dashboard,
      });
    } else {
      updateMutation.mutate(values);
    }
  };

  useEffect(() => {
    form.reset(makeDefaultValues(record));
  }, [record, mode]);

  const componentTypes = _.map(_.keys(CHART_TYPES), (key) => {
    const ct = CHART_TYPES[key];
    return {
      value: key,
      display: ct.title,
    };
  });

  const { terminals } = useSessionData();
  const terminalOptions = terminals.map((t) => ({
    value: t.id.toString(),
    display: t.terminal_name,
  }));

  return (
    <CrudForm
      form={form}
      addTitle={"Add Component"}
      editTitle={"Edit Component"}
      onSubmit={onSubmit}
      onDelete={onDelete}
      mut={createMode ? createMutation : updateMutation}
    >
      <FormField
        control={form.control}
        name="title"
        render={({ field }) => <TextInput field={field} label={"Title"} />}
      />
      <div className="flex">
        <div className="w-1/2 pr-2">
          <FormField
            control={form.control}
            name="x"
            render={({ field }) => <TextInput field={field} label={"X"} />}
          />
        </div>
        <div className="w-1/2 pl-2">
          <FormField
            control={form.control}
            name="y"
            render={({ field }) => <TextInput field={field} label={"Y"} />}
          />
        </div>
      </div>

      <div className="flex">
        <div className="w-1/2 pr-2">
          <FormField
            control={form.control}
            name="width"
            render={({ field }) => <TextInput field={field} label={"Width"} />}
          />
        </div>
        <div className="w-1/2 pl-2">
          <FormField
            control={form.control}
            name="height"
            render={({ field }) => <TextInput field={field} label={"Height"} />}
          />
        </div>
      </div>
      <FormField
        control={form.control}
        name="chart_type"
        render={({ field }) => (
          <SelectInput
            field={field}
            label={"Component Type"}
            options={componentTypes}
            placeholder={"Select Component"}
          />
        )}
      />
      <FormField
        control={form.control}
        name="terminal_id"
        render={({ field }) => (
          <SelectInput
            nullable={true}
            field={field}
            label={"Terminal"}
            options={terminalOptions}
            placeholder={"Select Terminal"}
          />
        )}
      />
      <FormField
        control={form.control}
        name="zone_type"
        render={({ field }) => (
          <SelectInput
            nullable={true}
            field={field}
            label={"Zone Type"}
            options={zoneTypeOptions}
            placeholder={"Select Zone Type"}
          />
        )}
      />
      <FormField
        control={form.control}
        name="measure"
        render={({ field }) => (
          <SelectInput
            nullable={true}
            field={field}
            label={"Zone Measure"}
            options={zoneMeasureOptions}
            placeholder={"Select Zone Measure"}
          />
        )}
      />
      <FormField
        control={form.control}
        name="direction"
        render={({ field }) => (
          <SelectInput
            nullable={true}
            field={field}
            label={"Zone Direction"}
            options={zoneDirectionOptions}
            placeholder={"Select Zone Direction"}
          />
        )}
      />
      <FormField
        control={form.control}
        name="flight_type"
        render={({ field }) => (
          <SelectInput
            nullable={true}
            field={field}
            label={"Flight Type"}
            options={zoneFlightTypeOptions}
            placeholder={"Select Flight Type"}
          />
        )}
      />
      <FormField
        control={form.control}
        name="pax_type"
        render={({ field }) => (
          <SelectInput
            nullable={true}
            field={field}
            label={"Pax Type"}
            options={zonePaxTypeOptions}
            placeholder={"Select Pax Type"}
          />
        )}
      />
    </CrudForm>
  );
};

const ComponentPage: React.FC = () => {
  const { dashboardId } = useParams<DashboardPageParams>();
  const { isLoading, data: components } = getComponents(parseInt(dashboardId));

  if (isLoading) {
    return null;
  }

  return (
    <CrudPage<Component>
      title={"Components"}
      addButton={"Add Component"}
      data={components}
      editForm={<ComponentForm dashboard={parseInt(dashboardId)} />}
      makeDetails={(r) => {
        return {
          name: r.title,
          chart_type: r.chart_type,
        };
      }}
      display={(r) => {
        return r.title;
      }}
    />
  );
};

export default ComponentPage;
