import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Ticketing,
  getTicketingByTerminal,
  createTicketing,
  updateTicketing,
  deleteTicketing,
} from "@/queries/ticketing";
import { SetupHeader, SetupItem } from "@/components/Setup";
import { z } from "zod";
import {
  CrudForm,
  CrudPage,
  FormMode,
  useCrudContext,
} from "@/components/page/CrudPage";
import {
  Checkpoint,
  CheckpointEdit,
  createCheckpoint,
  getCheckpointsByTerminal,
  updateCheckpoint,
} from "@/queries/checkpoints";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormField } from "@/components/ui/form";
import { TextInput } from "@/components/form/FormControls";
import { TerminalPageParams } from "@/lib/types";

const formSchema = z.object({
  name: z.string().min(1).max(10),
  carriers_iata: z.string(),
});

const TicketingForm: FunctionComponent<{
  terminal: number;
}> = ({ terminal }) => {
  const { record, setRecord, mode, setMode } = useCrudContext<Ticketing>();
  const createMode = mode === FormMode.Add;
  const makeDefaultValues = (ticketing: Ticketing) => {
    return {
      name: ticketing?.name || "",
      carriers_iata: ticketing?.carriers_iata.join(", ") || "",
    };
  };
  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: makeDefaultValues(record),
    resolver: zodResolver(formSchema),
  });

  const createMutation = createTicketing();
  const updateMutation = updateTicketing(record?.id || 0);
  const deleteMutation = deleteTicketing(record?.id || 0);

  function onDelete() {
    deleteMutation.mutate();
  }

  function onSubmit(values: z.infer<typeof formSchema>) {
    const carriers_iata = values.carriers_iata.split(", ").map((s) => s.trim());
    if (createMode) {
      createMutation.mutate({
        terminal,
        name: values.name,
        carriers_iata,
      });
    } else {
      updateMutation.mutate({
        name: values.name,
        carriers_iata,
      });
    }
  }

  useEffect(() => {
    form.reset(makeDefaultValues(record));
  }, [record, mode]);

  return (
    <CrudForm
      form={form}
      addTitle={"Add Ticketing"}
      editTitle={"Edit Ticketing"}
      onSubmit={onSubmit}
      onDelete={onDelete}
      mut={createMode ? createMutation : updateMutation}
    >
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <TextInput field={field} label={"Ticketing Name"} />
        )}
      />
      <FormField
        control={form.control}
        name="carriers_iata"
        render={({ field }) => (
          <TextInput
            field={field}
            label={"Supported Carriers"}
            description={
              "List of supported airlines' IATA codes (comma separated)"
            }
          />
        )}
      />
    </CrudForm>
  );
};

const TicketingPage: React.FC = () => {
  const { terminalId } = useParams<TerminalPageParams>();
  const { isLoading, data: tickets } = getTicketingByTerminal(
    parseInt(terminalId),
  );
  if (isLoading) {
    return null;
  }

  return (
    <CrudPage<Ticketing>
      title={"Ticketing"}
      addButton={"Add Ticketing"}
      data={tickets}
      editForm={<TicketingForm terminal={parseInt(terminalId)} />}
      makeDetails={(r) => {
        return {
          name: r.display,
          supported_carriers: r.carriers_iata.join(", "),
        };
      }}
      display={(r) => {
        return r.display;
      }}
    />
  );
};

export default TicketingPage;
