import React, { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  createGate,
  deleteGate,
  Gate,
  getGateByTerminal,
  updateGate,
} from "@/queries/gate";
import { z } from "zod";
import {
  CrudForm,
  CrudPage,
  FormMode,
  useCrudContext,
} from "@/components/page/CrudPage";
import { Checkpoint, getCheckpointsByTerminal } from "@/queries/checkpoints";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { SelectInput, TextInput } from "@/components/form/FormControls";
import { getTicketingByTerminal } from "@/queries/ticketing";
import { MultiSelect } from "@/components/MultiSelect";
import { TerminalPageParams } from "@/lib/types";
import { getConcoursesByTerminal } from "@/queries/concourses";
import { useSessionData } from "@/stores/SessionDataContext";

const formSchema = z.object({
  name: z.string().min(1).max(10),
  security: z.string(),
  tickets: z.array(z.number()),
  concourse: z.optional(z.string()),
});

const GateForm: FunctionComponent<{
  terminal: number;
}> = ({ terminal }) => {
  const { record, setRecord, mode, setMode } = useCrudContext<Gate>();
  const createMode = mode === FormMode.Add;
  const { airport_config } = useSessionData();

  const makeDefaultValues = (gate: Gate) => {
    return {
      name: gate?.name || "",
      security: gate?.security?.id.toString() || null,
      tickets: gate?.tickets?.map((t) => t.id) || [],
      concourse: gate?.concourse?.id.toString() || null,
    };
  };

  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: makeDefaultValues(record),
    resolver: zodResolver(formSchema),
  });

  const createMutation = createGate();
  const updateMutation = updateGate(record?.id || 0);
  const deleteMutation = deleteGate(record?.id || 0);

  function onDelete() {
    deleteMutation.mutate();
  }

  const { isLoading: checkpointLoading, data: checkpointList } =
    getCheckpointsByTerminal(terminal);
  const { isLoading: ticketingLoading, data: ticketingList } =
    getTicketingByTerminal(terminal);
  const { isLoading: concourseLoading, data: concourseList } =
    getConcoursesByTerminal(terminal);

  function onSubmit(values: z.infer<typeof formSchema>) {
    // console.log(values)
    if (createMode) {
      createMutation.mutate({
        terminal,
        name: values.name,
        security: parseInt(values.security),
        tickets: values.tickets,
        concourse: values.concourse ? parseInt(values.concourse) : null,
      });
    } else {
      updateMutation.mutate({
        name: values.name,
        security: parseInt(values.security),
        tickets: values.tickets,
        concourse: values.concourse ? parseInt(values.concourse) : null,
      });
    }
  }

  useEffect(() => {
    form.reset(makeDefaultValues(record));
  }, [record, mode]);

  if (checkpointLoading || ticketingLoading || concourseLoading) {
    return null;
  }

  return (
    <CrudForm
      form={form}
      addTitle={"Add Gate"}
      editTitle={"Edit Gate"}
      onSubmit={onSubmit}
      onDelete={onDelete}
      mut={createMode ? createMutation : updateMutation}
    >
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => <TextInput field={field} label={"Gate Name"} />}
      />
      <FormField
        control={form.control}
        name="security"
        render={({ field }) => (
          <SelectInput
            field={field}
            label={"Checkpoint"}
            options={checkpointList.map((c) => ({
              value: c.id.toString(),
              display: c.display,
            }))}
          />
        )}
      />
      {airport_config.has_concourse && (
        <FormField
          control={form.control}
          name="concourse"
          render={({ field }) => (
            <SelectInput
              nullable={true}
              field={field}
              placeholder={"No Concourse"}
              label={"Concourse"}
              options={concourseList.map((c) => ({
                value: c.id.toString(),
                display: c.name,
              }))}
            />
          )}
        />
      )}

      <FormField
        control={form.control}
        name="tickets"
        render={({ field }) => {
          // debugger
          return (
            <FormItem>
              <FormLabel>Linked Ticketings</FormLabel>
              <MultiSelect
                options={ticketingList}
                onChange={field.onChange}
                value={field.value}
              ></MultiSelect>
              <FormDescription>
                Select ticketings for this gate (multi select)
              </FormDescription>
              <FormMessage />
            </FormItem>
          );
        }}
      />
    </CrudForm>
  );
};

const GatePage: React.FC = () => {
  const { terminalId } = useParams<TerminalPageParams>();
  const { isLoading, data: gates } = getGateByTerminal(parseInt(terminalId));

  if (isLoading) {
    return null;
  }

  return (
    <CrudPage<Gate>
      title={"Gates"}
      addButton={"Add Gate"}
      data={gates}
      editForm={<GateForm terminal={parseInt(terminalId)} />}
      makeDetails={(r) => {
        return {
          name: r.display,
          security: r.security.display,
          ticketing: r.tickets.map((t) => t.display).join(", "),
        };
      }}
      display={(r) => {
        return r.display;
      }}
    />
  );
};

export default GatePage;
