import { FunctionComponent, useLayoutEffect, useRef, useState } from "react";
import { Component } from "@/queries/component";
import { getReportComponentData } from "@/queries/report";
import useResizeObserver from "@react-hook/resize-observer";
import { buildChartComponent } from "@/viz/Charts";
import { useMediaQuery } from "react-responsive";

interface DashComProps {
  component: Component;
  reportId: number;
}

function useComponentStyle(component: Component) {
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" }); // Mobile breakpoint

  return isMobile
    ? {}
    : {
        gridColumnStart: component.x + 1, // +1 because Grid lines start from 1.
        gridColumnEnd: component.x + component.width + 1, // +1 because ending grid line is exclusive.
        gridRowStart: component.y + 1, // +1 because Grid lines start from 1.
        gridRowEnd: component.y + component.height + 1, // +1 because ending grid line is exclusive.
      };
}

const DashCom: FunctionComponent<DashComProps> = ({ component, reportId }) => {
  // For debugging
  // if (component.chart_type !== "passengers_by_gate") {
  //   return null;
  // }

  // Track the size of the target
  const [size, setSize] = useState<DOMRectReadOnly | undefined>();
  const target = useRef(null);
  useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  const Chart = buildChartComponent(component?.chart_type || "");

  const {
    data: componentData,
    isLoading,
    isSuccess,
  } = getReportComponentData({ component: component.id, report: reportId });

  const gridStyle = useComponentStyle(component);

  return (
    <div
      data-chart-type={component.chart_type}
      className={
        "bg-slate-900 border-slate-600 border drop-shadow flex flex-col mt-2 h-90 md:h-auto"
      }
      style={gridStyle}
    >
      <div className={"px-4 py-4 text-slate-300 border-b border-slate-700"}>
        {component.title}
      </div>
      <div className={"p-4 w-full flex-grow"}>
        <div className={"h-full w-full highcharts-dark"} ref={target}>
          {isSuccess && size && (
            <Chart
              data={componentData}
              height={size?.height || 0}
              width={size?.width || 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashCom;
