import React, { useEffect } from "react";
import { useSessionData } from "@/stores/SessionDataContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useReportContext } from "@/stores/ReportContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardList() {
  const { dashboards, code_iata } = useSessionData();
  const navigate = useNavigate(); // Get navigate function
  const location = useLocation(); // Get current location
  const { forced, reportId } = useReportContext();

  const tabs = dashboards.map((d) => {
    let href = `/dashboard/${d.id}`;
    if (forced) {
      const url = new URL(href, window.location.origin);
      url.searchParams.set("report_id", reportId.toString());
      href = url.pathname + url.search;
    }
    return {
      name: d.name,
      href: href,
      current: location.pathname.startsWith(`/dashboard/${d.id}`),
    };
  });

  // Update page title based on selected dashboard
  useEffect(() => {
    const currentDashboard = tabs.find((tab) => tab.current);
    if (currentDashboard) {
      document.title = `RaidoHub | ${currentDashboard.name}`;
    } else {
      document.title = "RaidoHub | Dashboard List";
    }
  }, [location.pathname, tabs]);

  const handleTabChange = (e) => {
    const selectedHref = e.target.value;

    // Navigate to the new path
    navigate(selectedHref);
  };

  return (
    <div className={"mx-8 mt-4"}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a Dashboard
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 dark:bg-slate-900 dark:border-slate-600"
          onChange={handleTabChange} // Set the onChange handler
          defaultValue={tabs[0].href}
        >
          {tabs.map((tab) => (
            <option key={tab.href} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="isolate flex divide-x divide-slate-600 rounded shadow"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <Link
              key={tab.name}
              to={tab.href}
              className={classNames(
                tab.current
                  ? "text-gray-200"
                  : "text-gray-400 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 overflow-hidden bg-slate-800 py-3 px-6 text-center text-sm font-medium hover:bg-indigo-900 hover:text-indigo-200 focus:z-10",
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? "bg-indigo-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5",
                )}
              />
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
}
