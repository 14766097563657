import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createDashboard,
  Dashboard,
  deleteDashboard,
  getAllDashboards,
  updateDashboard,
} from "@/queries/dashboard";
import { SetupHeader, SetupItem } from "@/components/Setup";
import { z } from "zod";
import {
  CrudForm,
  CrudPage,
  FormMode,
  useCrudContext,
} from "@/components/page/CrudPage";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  createTerminal,
  getTerminals,
  updateTerminal,
} from "@/queries/terminals";
import { FormField } from "@/components/ui/form";
import { TextInput } from "@/components/form/FormControls";

const formSchema = z.object({
  name: z.string().min(1).max(255),
});

const DashboardForm: FunctionComponent = () => {
  const { record, setRecord, mode, setMode } = useCrudContext<Dashboard>();
  const createMode = mode === FormMode.Add;
  const makeDefaultValues = (dashboard?: Dashboard) => {
    return {
      name: dashboard?.name,
    };
  };

  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: makeDefaultValues(record),
    resolver: zodResolver(formSchema),
  });

  const createMutation = createDashboard();
  const updateMutation = updateDashboard(record?.id || 0);
  const deleteMutation = deleteDashboard(record?.id || 0);

  function onDelete() {
    deleteMutation.mutate();
  }

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (createMode) {
      createMutation.mutate({ name: values.name });
    } else {
      updateMutation.mutate({ name: values.name });
    }
  };

  useEffect(() => {
    form.reset(makeDefaultValues(record));
  }, [record, mode]);

  return (
    <CrudForm
      form={form}
      addTitle={"Add Dashboard"}
      editTitle={"Edit Dashboard"}
      onSubmit={onSubmit}
      onDelete={onDelete}
      mut={createMode ? createMutation : updateMutation}
    >
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <TextInput field={field} label={"Dashboard Name"} />
        )}
      />
    </CrudForm>
  );
};

const ManageDashboardPage: React.FC = () => {
  const { isLoading, data: dashboards } = getAllDashboards();
  if (isLoading) {
    return null;
  }

  return (
    <CrudPage<Dashboard>
      title={"Dashboards"}
      addButton={"Add Dashboard"}
      data={dashboards}
      editForm={<DashboardForm />}
      makeDetails={(r) => {
        return { name: r.name };
      }}
      display={(r) => {
        return r.name;
      }}
    />
  );
};

export default ManageDashboardPage;
