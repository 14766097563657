import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "@/queries/client";
import { components } from "@/lib/openapi";

export function getTerminals() {
  return useQuery({
    queryKey: ["terminals"],
    queryFn: async ({ signal }) => {
      const { data, error } = await client.get().GET("/api/terminals", {
        signal,
      });
      if (data) return data;
      throw new Error(error);
    },
  });
}

export function createTerminal() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      createData: components["schemas"]["TerminalCreateSchema"],
    ) => {
      const { data, error } = await client.get().POST("/api/terminals", {
        body: createData,
      });
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["terminals"] });
    },
  });
}

export function updateTerminal(terminal_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      updateData: components["schemas"]["TerminalUpdateSchema"],
    ) => {
      const { data, error } = await client
        .get()
        .POST("/api/terminals/{terminal_id}", {
          params: {
            path: {
              terminal_id,
            },
          },
          body: updateData,
        });
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["terminals"] });
    },
  });
}

export function deleteTerminal(terminal_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data, error } = await client
        .get()
        .DELETE("/api/terminals/{terminal_id}", {
          params: {
            path: {
              terminal_id,
            },
          },
        });
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["terminals"] });
    },
  });
}
