import React, { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Checkpoint,
  CheckpointEdit,
  createCheckpoint,
  deleteCheckpoint,
  getCheckpointsByTerminal,
  updateCheckpoint,
} from "@/queries/checkpoints";
import {
  CrudForm,
  CrudPage,
  FormMode,
  useCrudContext,
} from "@/components/page/CrudPage";
import { useForm } from "react-hook-form";
import { FormField } from "@/components/ui/form";
import { TextInput } from "@/components/form/FormControls";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TerminalPageParams } from "@/lib/types";

const formSchema = z.object({
  name: z.string().min(1).max(5),
});

const CheckpointForm: FunctionComponent<{
  terminal: number;
}> = ({ terminal }) => {
  const { record, setRecord, mode, setMode } = useCrudContext<Checkpoint>();
  const createMode = mode === FormMode.Add;
  const makeDefaultValues = (checkpoint: Checkpoint) => {
    return {
      name: checkpoint?.name || "",
    };
  };
  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: makeDefaultValues(record),
    resolver: zodResolver(formSchema),
  });

  const createMutation = createCheckpoint();
  const updateMutation = updateCheckpoint(record?.id || 0);
  const deleteMutation = deleteCheckpoint(record?.id || 0);

  function onDelete() {
    deleteMutation.mutate();
  }

  function onSubmit(values: CheckpointEdit) {
    if (createMode) {
      createMutation.mutate({
        terminal,
        name: values.name,
      });
    } else {
      updateMutation.mutate(values);
    }
  }

  useEffect(() => {
    form.reset(makeDefaultValues(record));
  }, [record, mode]);

  return (
    <CrudForm
      form={form}
      addTitle={"Add Checkpoint"}
      editTitle={"Edit Checkpoint"}
      onSubmit={onSubmit}
      onDelete={onDelete}
      mut={createMode ? createMutation : updateMutation}
    >
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <TextInput field={field} label={"Checkpoint Name"} />
        )}
      />
    </CrudForm>
  );
};

const CheckpointsPage: React.FC = () => {
  const { terminalId } = useParams<TerminalPageParams>();
  const { isLoading, data: checkpoints } = getCheckpointsByTerminal(
    parseInt(terminalId),
  );

  if (isLoading) {
    return null;
  }

  return (
    <CrudPage<Checkpoint>
      title={"Checkpoints"}
      addButton={"Add Checkpoint"}
      data={checkpoints}
      editForm={<CheckpointForm terminal={parseInt(terminalId)} />}
      makeDetails={(r) => {
        return { name: r.display };
      }}
      display={(r) => {
        return r.display;
      }}
    />
  );
};

export default CheckpointsPage;
