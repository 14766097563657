import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useContext,
  useState,
} from "react";
import { SetupHeader, SetupItem } from "@/components/Setup";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CrossCircledIcon,
} from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import _ from "lodash";
import { useSessionData } from "@/stores/SessionDataContext";

interface IRecord {
  id: number;
}

interface CrudContextType<T extends IRecord> {
  record?: T;
  setRecord: React.Dispatch<React.SetStateAction<T | undefined>>;
  mode: FormMode;
  setMode: React.Dispatch<React.SetStateAction<FormMode>>;
}

const CrudContext = React.createContext<CrudContextType<any> | undefined>(
  undefined,
);

export function useCrudContext<T extends IRecord>(): CrudContextType<T> {
  const context = useContext(CrudContext);

  if (context === undefined) {
    throw new Error("useCrudContext must be used within a CrudProvider");
  }

  return context as CrudContextType<T>;
}

export enum FormMode {
  Hidden,
  Add,
  Edit,
}

interface CrudFormProps {
  error?: string;
  addTitle: string;
  editTitle: string;
  form: any;
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  mut: any;
}

export const CrudForm = (
  props: PropsWithChildren<CrudFormProps>,
): ReactElement => {
  const { mode, setMode, setRecord } = useContext(CrudContext);
  const createMode = mode === FormMode.Add;
  const error = props.mut.error;
  const success = props.mut.isSuccess;

  const { is_admin: isAdmin } = useSessionData();

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      if (props.onDelete) {
        props.onDelete();
        setRecord(undefined);
        setMode(FormMode.Hidden);
      }
    }
  };

  const form = props.form;
  return (
    <Form {...form}>
      <div className="flex items-center">
        <div className={"text-2xl font-bold mb-6 flex-grow"}>
          {createMode ? props.addTitle : props.editTitle}
        </div>
        <Button
          variant={"outline"}
          onClick={() => {
            setMode(FormMode.Hidden);
            setRecord(undefined);
          }}
        >
          <CrossCircledIcon height={16} width={16} />
        </Button>
      </div>
      {error && (
        <div className="error-msg">
          <p>There was an error processing your request.</p>
        </div>
      )}
      {success && (
        <div className="success-msg">
          <p>
            {createMode ? "Created Successfully" : "Changes successfully saved"}
          </p>
        </div>
      )}
      <form onSubmit={form.handleSubmit(props.onSubmit)} className="space-y-8">
        {props.children}
        <div className="flex items-center justify-between">
          <Button type="submit" disabled={!isAdmin}>
            Save
          </Button>
          {!createMode && props.onDelete && (
            <Button
              type={"button"}
              onClick={handleDelete}
              variant={"destructive"}
              disabled={!isAdmin}
              className={""}
            >
              Delete
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
};

const DetailList: React.FC<{
  details: { [key: string]: string | number | boolean };
}> = ({ details }) => {
  return (
    <dl>
      {Object.entries(details).map(([key, value]) => {
        const formattedKey = _.startCase(_.lowerCase(key));
        return (
          <div key={key}>
            <dt className="inline-block text-xs font-bold text-gray-200 mr-2">
              {formattedKey}:
            </dt>
            <dd className="inline-block text-xs text-gray-300">
              {value.toString()}
            </dd>
          </div>
        );
      })}
    </dl>
  );
};

export const CrudPage = <T extends IRecord>({
  title,
  addButton,
  data,
  makeDetails,
  editForm,
  display,
}: {
  title: string;
  addButton: string;
  data: T[];
  editForm: React.JSX.Element;
  display: (record: T | undefined) => string;
  makeDetails: (record: T | undefined) => Record<string, string>;
}) => {
  const [record, setRecord] = useState<T | undefined>();
  const [mode, setMode] = useState<FormMode>(FormMode.Hidden);

  const newRecord = () => {
    setMode(FormMode.Add);
    setRecord(undefined);
  };
  const editRecord = (record: T) => {
    setMode(FormMode.Edit);
    setRecord(record);
  };

  const CrudRow: React.FC<{ r: T }> = ({ r }) => {
    const [open, setOpen] = useState(false);

    return (
      <div
        onClick={() => setOpen(!open)}
        className={cn([
          "border-b border-slate-600  cursor-pointer",
          record?.id === r.id ? "bg-slate-700" : "even:bg-slate-900",
        ])}
      >
        <div className="flex items-center py-2 px-4">
          <div className="flex-grow">{display(r)}</div>
          {record?.id !== r.id && (
            <div className={"mr-4"}>
              <Button
                variant={"outline"}
                size={"sm"}
                onClick={() => editRecord(r)}
              >
                Edit
              </Button>{" "}
            </div>
          )}
          <div>{open ? <ChevronDownIcon /> : <ChevronRightIcon />}</div>
        </div>
        {open && (
          <div className={"border-t border-slate-400 p-4"}>
            <DetailList details={makeDetails(r)} />
          </div>
        )}
      </div>
    );
  };

  return (
    <CrudContext.Provider value={{ record, setRecord, mode, setMode }}>
      <div>
        <div className={"h-full"}>
          <div className="flex h-full">
            <div className="w-1/2 border-r border-slate-600">
              <SetupHeader
                title={title}
                addButton={addButton}
                onAdd={newRecord}
              />
              {data.map((r) => {
                return <CrudRow r={r} key={r.id} />;
              })}
            </div>
            <div className="w-1/2 p-4">
              {mode !== FormMode.Hidden && editForm}
            </div>
          </div>
        </div>
      </div>
    </CrudContext.Provider>
  );
};
