import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { SelectInput } from "@/components/form/FormControls";
import { Form, FormField } from "@/components/ui/form";
import { ExploreConfig } from "@/types/explore";
import {
  zoneDirectionOptions,
  zoneMeasureOptions,
  zonePaxTypeOptions,
  zoneTypeOptions,
} from "@/pages/setup/constants";
import { useSessionData } from "@/stores/SessionDataContext";
import { ExploreView } from "@/components/explore/ExploreView";
import ConfigBuilder from "@/pages/explore/ConfigBuilder";

export const ExplorePage: FunctionComponent = () => {
  // Extract defaults into a function so we can build saved reports later on.
  const { terminals } = useSessionData();

  useEffect(() => {
    // Set the page title
    document.title = "RaidoHub | Explore Data";
  }, []);

  const buildDefaults = (): ExploreConfig => ({
    terminal_id: terminals[0]?.id.toString() || "",
    direction: "dep",
    type: "gate",
    pax_type: "",
    measure: "pax",
  });

  const form = useForm<ExploreConfig>({
    defaultValues: buildDefaults(),
  });

  const formValues = form.watch();
  const [exploreConfig, setExploreConfig] = useState<ExploreConfig>({
    terminal_id: null,
    type: null,
    measure: null,
    direction: null,
    flight_type: null,
    pax_type: null,
    concourse_id: null,
    restrict_by: null,
  });

  const configReady = exploreConfig.measure !== null;

  const terminalOptions =
    terminals.map((t) => ({
      value: t.id.toString(),
      display: t.terminal_name,
    })) || [];

  return (
    <div className={"mx-12 mt-8"}>
      <div className={"text-2xl mb-4"}>Explore Forecast Data</div>
      <ConfigBuilder onConfigReady={setExploreConfig}></ConfigBuilder>
      <div className={"mt-4 w-full"}>
        {configReady && <ExploreView config={exploreConfig} />}
      </div>
    </div>
  );
};
