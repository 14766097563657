import React, { FunctionComponent } from "react";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Control } from "react-hook-form";
import {
  Select as SingleSelect,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";

interface FormOption {
  value: string;
  display: string;
}

interface FormElementProps {
  field: any;
  label: string;
  description?: string;
  options?: FormOption[];
  placeholder?: string;
  nullable?: boolean;
}
export const TextInput: FunctionComponent<FormElementProps> = ({
  field,
  label,
  description,
}) => {
  return (
    <FormItem>
      <FormLabel>{label}</FormLabel>
      <FormControl>
        <Input placeholder="" {...field} />
      </FormControl>
      {description && <FormDescription>{description}</FormDescription>}
      <FormMessage />
    </FormItem>
  );
};

export const TextareaInput: FunctionComponent<FormElementProps> = ({
  field,
  label,
  description,
}) => {
  return (
    <FormItem>
      <FormLabel>{label}</FormLabel>
      <FormControl>
        <Textarea placeholder="" {...field} />
      </FormControl>
      {description && <FormDescription>{description}</FormDescription>}
      <FormMessage />
    </FormItem>
  );
};

export const SelectInput: FunctionComponent<FormElementProps> = ({
  field,
  label,
  description,
  options,
  placeholder,
  nullable,
}) => {
  const handleChange = (val: string) => {
    if (val === "__null__") {
      field.onChange("");
    } else {
      field.onChange(val);
    }
  };
  const value = field.value === "" ? "__null__" : field.value;

  return (
    <FormItem>
      <FormLabel>{label}</FormLabel>
      <SingleSelect onValueChange={handleChange} value={value}>
        <FormControl>
          <SelectTrigger>
            {nullable ? (
              <SelectValue />
            ) : (
              <SelectValue placeholder={placeholder} />
            )}
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {nullable && (
            <SelectItem value={"__null__"}>{placeholder}</SelectItem>
          )}
          {options.map((opt, index) => {
            return (
              <SelectItem value={opt.value} key={label + index}>
                {opt.display}
              </SelectItem>
            );
          })}
        </SelectContent>
      </SingleSelect>
      {description && <FormDescription>{description}</FormDescription>}
      <FormMessage />
    </FormItem>
  );
};
