import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "@/queries/client";
import { components } from "@/lib/openapi";

export type Ticketing = components["schemas"]["TicketingSchema"];

export function getTicketingByTerminal(terminal: number) {
  return useQuery({
    queryKey: ["tickets", terminal],
    queryFn: async ({ signal }) => {
      const { data, error } = await client.get().GET("/api/tickets", {
        signal,
        params: {
          query: {
            terminal: terminal,
          },
        },
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function createTicketing() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      createData: components["schemas"]["TicketingCreateSchema"],
    ) => {
      const { data, error } = await client.get().POST("/api/tickets", {
        body: createData,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["tickets"] });
    },
  });
}

export function updateTicketing(ticketing_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      updateData: components["schemas"]["TicketingUpdateSchema"],
    ) => {
      const { data, error } = await client
        .get()
        .PUT("/api/tickets/{ticketing_id}", {
          params: {
            path: {
              ticketing_id,
            },
          },
          body: updateData,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["tickets"] });
    },
  });
}

export function deleteTicketing(ticketing_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data, error } = await client
        .get()
        .DELETE("/api/tickets/{ticketing_id}", {
          params: {
            path: {
              ticketing_id,
            },
          },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["tickets"] });
    },
  });
}
