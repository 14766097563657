import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "@/queries/client";
import { components } from "@/lib/openapi";

export type Concourse = components["schemas"]["ConcourseSchema"];
export type ConcourseEdit = components["schemas"]["ConcourseUpdateSchema"];

export function getConcoursesByTerminal(terminal: number) {
  return useQuery({
    queryKey: ["concourses", terminal],
    queryFn: async ({ signal }) => {
      const { data, error } = await client.get().GET("/api/concourses", {
        signal,
        params: {
          query: {
            terminal: terminal,
          },
        },
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function createConcourse() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      createData: components["schemas"]["ConcourseCreateSchema"],
    ) => {
      const { data, error } = await client.get().POST("/api/concourses", {
        body: createData,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["concourses"] });
    },
  });
}

export function updateConcourse(concourse_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      updateData: components["schemas"]["ConcourseUpdateSchema"],
    ) => {
      const { data, error } = await client
        .get()
        .PUT("/api/concourses/{concourse_id}", {
          params: {
            path: {
              concourse_id,
            },
          },
          body: updateData,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["concourses"] });
    },
  });
}

export function deleteConcourse(concourse_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data, error } = await client
        .get()
        .DELETE("/api/concourses/{concourse_id}", {
          params: {
            path: {
              concourse_id,
            },
          },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["concourses"] });
    },
  });
}
