import React, { FunctionComponent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Concourse,
  ConcourseEdit,
  createConcourse,
  deleteConcourse,
  getConcoursesByTerminal,
  updateConcourse,
} from "@/queries/concourses";
import {
  CrudForm,
  CrudPage,
  FormMode,
  useCrudContext,
} from "@/components/page/CrudPage";
import { FormField } from "@/components/ui/form";
import { TextInput } from "@/components/form/FormControls";
import { useParams } from "react-router-dom";
import { TerminalPageParams } from "@/lib/types";

const formSchema = z.object({
  name: z.string().min(1).max(10),
  notes: z.string().optional().nullable(),
});

const ConcourseForm: FunctionComponent<{
  terminal: number;
}> = ({ terminal }) => {
  const { record, setRecord, mode, setMode } = useCrudContext<Concourse>();
  const createMode = mode === FormMode.Add;

  const makeDefaultValues = (concourse: Concourse) => {
    return {
      name: concourse?.name || "",
      notes: concourse?.notes || "",
    };
  };

  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: makeDefaultValues(record),
    resolver: zodResolver(formSchema),
  });

  const createMutation = createConcourse();
  const updateMutation = updateConcourse(record?.id || 0);
  const deleteMutation = deleteConcourse(record?.id || 0);

  function onDelete() {
    deleteMutation.mutate();
  }

  function onSubmit(values: ConcourseEdit) {
    if (createMode) {
      createMutation.mutate({
        terminal,
        name: values.name,
        notes: values.notes || "",
      });
    } else {
      updateMutation.mutate(values);
    }
  }

  useEffect(() => {
    form.reset(makeDefaultValues(record));
  }, [record, mode]);

  return (
    <CrudForm
      form={form}
      addTitle={"Add Concourse"}
      editTitle={"Edit Concourse"}
      onSubmit={onSubmit}
      onDelete={onDelete}
      mut={createMode ? createMutation : updateMutation}
    >
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <TextInput field={field} label={"Concourse Name"} />
        )}
      />
      <FormField
        control={form.control}
        name="notes"
        render={({ field }) => <TextInput field={field} label={"Notes"} />}
      />
    </CrudForm>
  );
};

const ConcoursesPage: React.FC = () => {
  const { terminalId } = useParams<TerminalPageParams>();
  const { isLoading, data: concourses } = getConcoursesByTerminal(
    parseInt(terminalId),
  );

  if (isLoading) {
    return null;
  }

  return (
    <CrudPage<Concourse>
      title={"Concourses"}
      addButton={"Add Concourse"}
      data={concourses}
      editForm={<ConcourseForm terminal={parseInt(terminalId)} />}
      makeDetails={(r) => {
        return { name: r.name };
      }}
      display={(r) => {
        return r.name;
      }}
    />
  );
};

export default ConcoursesPage;
